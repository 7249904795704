import * as pwMap from '../shared/map.js';
import {css, html, LitElement} from "lit";
import {url} from '../shared/lit-directives.js';
import close_button_style from '../shared/components/close-button-style.js';
import location_marker from '../../images/location-marker.svg';
import {isSmartPhone} from "../platform.js";
import {showSpinner, hideSpinner} from "../website-map-page-base.js";
import {classMap} from "lit/directives/class-map.js";

const
    gettext = window.gettext,
    title_text = gettext("Save Forecast Location"),
    subtitle_text = gettext("Saving will create a new Forecast Location for your account at this position"),
    save_text = gettext("Save"),
    cancel_text = gettext("Cancel"),
    input_placeholder_text = gettext("Location Name"),
    empty_location_name_text = gettext("Please enter a location name"),
    csrftoken = $j("[name=csrfmiddlewaretoken]").val(),
    addLocationUrl = $j('body').attr('data-add-location-url'),
    save_error_text = gettext("Unable to save location, please try again later"),
    confirm_save_text = gettext("Forecast Location successfully saved");

export default class PWMapAddLocationModal extends LitElement {
    static get properties() {
        return {
            lat: {type: Number},
            lon: {type: Number},
            rejectSubmit: {type: Boolean},
            showPostSaveModal: {type: Boolean}
        };
    }

    constructor() {
        super();
        this.lat = 0;
        this.lon = 0;
        this.formattedCoords = '';
        this.inputValue = '';
        // If we dont do this, using the left/right arrow keys or space bar within the location name input ends up controlling the map slider
        this.disableSliderEvents = (event) => {
            event.stopPropagation()
        }
        this.disableFormSubmit = false;
        this.rejectSubmit = false;
        this.showPostSaveModal = false;
        this.postSaveError = false;
    }

    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('keypress', this.disableSliderEvents);
        this.addEventListener('keydown', this.disableSliderEvents);
        this.addEventListener('keyup', this.disableSliderEvents);
    }

    disconnectedCallback() {
        this.removeEventListener('keypress', this.disableSliderEvents);
        this.removeEventListener('keydown', this.disableSliderEvents);
        this.removeEventListener('keyup', this.disableSliderEvents);
        super.disconnectedCallback();
    }

    updated(changedProperties) {
        if (changedProperties.has('lon')) {
            this.lon = window.Atlas.modulateLon(this.lon);
        }
    }

    close() {
        this.inputValue = '';
        this.rejectSubmit = false;
        this.showPostSaveModal = false;
        this.postSaveError = false;
        const form = this.renderRoot.querySelector('form');
        form.reset();
        this.dispatchEvent(new CustomEvent('map-add-location-modal-close', {
            bubbles: true,
            composed: true
        }));
    }

    save() {
        // Prevent the form being submitted twice on double click
        if (!this.disableFormSubmit) {
            if (!this.renderRoot.querySelector('#location_name').value) {
                this.rejectSubmit = true;
                return;
            } else {
                this.rejectSubmit = false;
            }
            this.disableFormSubmit = true;
            const
                form = this.renderRoot.querySelector('form'),
                that = this;
            $j('#add-location-modal').each((key, element) => {
                if (element.open) element.close();
            });
            showSpinner();
            if (isSmartPhone) {
                $j
                    .ajax({
                        url: form.action,
                        method: form.method,
                        data: $j(form).serialize(),
                        headers: {'X-CSRFTOKEN': csrftoken}
                    })
                    .done(response => {
                        if (response.status == 'OK') {
                            for (const key of Object.keys(response.data || {})) {
                                app.setData(key, response.data[key]);
                            }
                            for (const key of Object.keys(response.settings || {})) {
                                app.setSetting(key, response.settings[key]);
                            }
                            that.showPostSaveModal = true;
                        } else if (response.status == 'error') {
                            alert(response.error || errstr);
                        } else {
                            // A 2xx response but not an object we understand; probably HTML.
                            // Guess that there are too many locations / too many changes.
                            location.reload();
                        }
                        // No return value.
                    })
                    .fail(function () {
                        alert(save_error_text);
                    })
                    .always(() => {
                        hideSpinner();
                        $j('#add-location-modal').each((key, element) => {
                            if (!element.open) element.showModal();
                        });
                    });
            } else {
                $j
                    .ajax({
                        url: form.action,
                        method: form.method,
                        data: $j(form).serialize(),
                        headers: {'X-CSRFTOKEN': csrftoken}
                    })
                    .done(response => {
                        if (response.status == 'OK') {
                            var newLocation = {
                                'name': response.name,
                                'lon': parseFloat(response.lon),
                                'lat': parseFloat(response.lat),
                                'paid': response.paid,
                                'id': response.id
                            }
                            that.dispatchEvent(new CustomEvent('new-location-added', {
                                detail: {newLocation},
                                bubbles: true,
                                composed: true
                            }));
                            hideSpinner();
                            that.showPostSaveModal = true;
                            $j('#add-location-modal').each((key, element) => {
                                if (!element.open) element.showModal();
                            })
                        } else {
                            // Probably recieved HTML indicating too many locations, so
                            // redirect to /location/add to display locationLimit.html
                            location.href = "/location/add";
                        }
                    })
                    .fail(function () {
                        that.postSaveError = true;
                        hideSpinner();
                        that.showPostSaveModal = true;
                        $j('#add-location-modal').each((key, element) => {
                            if (!element.open) element.showModal();
                        })
                    });
            }
            setTimeout(() => this.disableFormSubmit = false, 200);
        }
    }

    updateInputValue(event) {
        this.inputValue = event.target.value;
    }

    handleEnterKey(event) {
        if (event.key === "Enter") {
            this.save();
            event.preventDefault();
        }
    }

    renderErrorMessage() {
        return this.rejectSubmit
            ? html`
                    <div class="empty-location">${empty_location_name_text}</div>`
            : html``;
    }

    render() {
        this.formattedCoords = pwMap.utils.formatCoords(this.lat, this.lon).toUpperCase();
        const emptyLocationName = this.rejectSubmit ? empty_location_name_text : '';
        return html`
            <form method="post" action="${addLocationUrl}" class="${classMap({"inactive": this.showPostSaveModal})}">
                <div class="add-location-modal">
                    <span class="close" @click="${this.close}"></span>
                    <div class="modal-content title">${title_text}</div>
                    <div class="modal-content subtitle">${subtitle_text}</div>
                    <div class="modal-content location-wrapper">
                        <input name="name" class="location-name" id="location_name"
                               placeholder="${input_placeholder_text}" value="${this.inputValue}"
                               maxlength="32"
                               @input="${this.updateInputValue}"
                               @keydown="${this.handleEnterKey}"
                        >
                    </div>
                    ${this.renderErrorMessage()}
                    <div class="modal-content coords"><span class="location-marker"></span><span
                            class="coords-text">${this.formattedCoords}</span></div>
                    <div class="modal-content buttons">
                        <span class="cancel-button" @click="${this.close}">${cancel_text}</span><span
                            class="save-button" @click="${this.save}">${save_text}</span>
                    </div>
                </div>
                <input type="hidden" value="${this.lat.toFixed(3)}" name="latitude">
                <input type="hidden" value="${this.lon.toFixed(3)}" name="longitude">
                <input type="hidden" value="${csrftoken}" name="csrfmiddlewaretoken">
            </form>
            <div class="${classMap({"add-location-modal": true, "inactive": !this.showPostSaveModal})}">
                <span class="close" @click="${this.close}"></span>
                <p class="post-save-message">${this.postSaveError ? save_error_text : confirm_save_text}</p>
            </div>
        `;
    }

    static get styles() {
        const s = css`
          :host {
            display: block;
          }

          .add-location-modal {
            width: 420px;
            height: fit-content;
            background: #EFEFF4;
            border-radius: 10px;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
            padding: 12px 12px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            flex-direction: column;
          }

          .modal-content {
            display: flex;
            justify-content: center;
            padding: 6px 0px;
          }

          .title {
            font-weight: bold;
            font-size: 27px;
            padding-top: 8px;
          }

          .subtitle {
            font-size: 14px;
            text-align: center;
            width: 75%;
          }

          .close {
            position: absolute;
            top: 24px;
            right: calc(50% - 202px);
          }

          .location-wrapper {
            width: 90%;
          }

          .location-name {
            border: 1px solid #DFDFDF;
            border-radius: 5px;
            width: 100%;
            padding: 9px 13px;
          }

          .location-name:focus {
            outline: none;
          }

          .coords {
            opacity: 0.5;
            font-size: 14px;
          }

          .location-marker {
            background: ${url(location_marker)} no-repeat center;
            background-size: 15px 20px;
            display: inline-block;
            width: 15px;
            height: 20px;
            margin-right: 14px;
          }

          .coords-text {
            height: 20px;
            display: flex;
            padding: 3px 0px;
            align-items: center;
          }

          .buttons {
            width: 100%;
          }

          .buttons > span {
            border-radius: 8px;
            display: inline-block;
            width: 32%;
            padding: 8px 0px;
            text-align: center;
            font-weight: 600;
            font-size: 13px;
            cursor: pointer;
          }

          .cancel-button {
            margin-right: 5px;
            border: 1px solid #2996FC;
            color: #2996FC;
          }

          .save-button {
            margin-left: 5px;
            background: #2996FC;
            color: white;
          }

          .empty-location {
            margin: 5px;
            color: red;
            font-style: italic;
            font-weight: bold;
          }

          .inactive {
            display: none;
          }
        `
        const cssResult = [s, close_button_style];

        if (isSmartPhone) {
            cssResult.push(css`

              .add-location-modal {
                width: 100%;
                position: relative;
              }

              .title {
                font-size: 18px;
              }

              .subtitle {
                width: 100%;
                font-size: 13px;
              }

              .location-name {
                width: 100%;
              }

              .buttons > span {
                width: 40%;
              }

              .close {
                top: 12px;
                right: 12px;
                width: 20px;
                height: 20px;
                background-size: 20px;
              }

              .empty-location {
                font-size: 14px;
              }

              .post-save-message {
                font-size: 16px;
                margin: 0px 25px 0px 0px;
                font-weight: 400;
              }

            `);
        }

        return cssResult;
    }
}
